import React from 'react';
import styled from '@emotion/styled';

import LinksContainerDesktop from './InternalLinksContainer';
import LegalLinks from './LegalLinks';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div({
  background: 'radial-gradient(76.3% 131.14% at 73.91% 64.28%, #103E4B 0%, #000000 100%)',
  padding: `57px ${WEB_SIDE_PADDING} 30px ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    padding: `57px ${MOBILE_SIDE_PADDING} 30px ${MOBILE_SIDE_PADDING}`,
  },
});

const DesktopFooter: React.FC = () => (
  <Container>
    <LinksContainerDesktop />
    <LegalLinks />
  </Container>
);

export default DesktopFooter;
