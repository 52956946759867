import React from 'react';
import styled from '@emotion/styled';

import DesktopHeroImg from 'assets/others/affiliates/affiliate_hero_desktop.jpg';
import MobileHeroImg from 'assets/others/affiliates/affiliate_hero_mobile.jpg';
import Button from 'components/Button';
import {
  BREAKPOINTS,
  EXTERNAL_LINKS,
  MOBILE_SIDE_PADDING,
  WEB_SIDE_PADDING,
} from 'utils/constants';

const DesktopContainer = styled.div({
  backgroundImage: `url(${DesktopHeroImg})`,
  backgroundPosition: 'center top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: 740,
  padding: `0 ${WEB_SIDE_PADDING}`,
  display: 'flex',
  alignItems: 'center',
  [BREAKPOINTS[800]]: {
    display: 'none',
  },
});

const MobileContainer = styled.div({
  display: 'none',
  [BREAKPOINTS[800]]: { display: 'contents' },
});

const MobileImage = styled.div({
  backgroundImage: `url(${MobileHeroImg})`,
  backgroundPosition: 'center top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: 540,
});

const Title = styled.div((props) => ({
  color: props.theme.colors.lightSand,
  fontFamily: 'Taviraj',
  fontSize: 70,
  lineHeight: '86px',
  fontWeight: 700,
  letterSpacing: 0.2,
  [BREAKPOINTS[800]]: {
    color: props.theme.colors.backgroundDark,
    fontSize: 30,
    lineHeight: '44px',
  },
}));

const Subtitle = styled.div((props) => ({
  color: props.theme.colors.lightSand,
  opacity: 0.9,
  fontSize: 16,
  lineHeight: '28px',
  marginTop: 18,
  letterSpacing: 0.2,
  marginBottom: 42,
  [BREAKPOINTS[800]]: {
    color: props.theme.colors.backgroundDark,
    fontSize: 16,
    lineHeight: '28px',
  },
}));

const TitlesContainer = styled.div((props) => ({
  maxWidth: 600,
  [BREAKPOINTS[800]]: {
    backgroundColor: props.theme.colors.lightSand,
    padding: `24px ${MOBILE_SIDE_PADDING} 0 ${MOBILE_SIDE_PADDING}`,
    maxWidth: 'unset',
  },
}));

const StyledButton = styled(Button)((props) => ({
  paddingLeft: 32,
  paddingRight: 32,
  [BREAKPOINTS[800]]: {
    color: props.theme.colors.white,
    backgroundColor: props.theme.colors.buttonGray,
    maxWidth: 'unset',
    alignSelf: 'stretch',
    ':hover': {
      backgroundColor: 'rgba(45, 55, 57, 0.9)',
    },
  },
  [BREAKPOINTS[440]]: {
    display: 'block',
  },
}));

const DesktopHero: React.FC = () => (
  <DesktopContainer>
    <TitlesContainer>
      <Title>Join Yours app affiliate program</Title>
      <Subtitle>
        With over 300+ meditations, Yours app helps people to work on improving their physical and
        mental well-being. Refer users to Yours app and earn commission.
      </Subtitle>
      <StyledButton text="Become an Affiliate" href={EXTERNAL_LINKS.AFF_YOURS_APP} />
    </TitlesContainer>
  </DesktopContainer>
);

const MobileHero: React.FC = () => (
  <MobileContainer>
    <MobileImage />
    <TitlesContainer>
      <Title>Join Yours app affiliate program</Title>
      <Subtitle>
        With over 300+ meditations, Yours app helps people to work on improving their physical and
        mental well-being. Refer users to Yours app and earn commission.
      </Subtitle>
      <StyledButton text="Become an Affiliate" href={EXTERNAL_LINKS.AFF_YOURS_APP} />
    </TitlesContainer>
  </MobileContainer>
);

const Hero: React.FC = () => (
  <>
    <DesktopHero />
    <MobileHero />
  </>
);

export default Hero;
