import React from 'react';
import styled from '@emotion/styled';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightSand,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `112px ${WEB_SIDE_PADDING} 92px ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    backgroundColor: props.theme.colors.lightSand,
    padding: `51px ${MOBILE_SIDE_PADDING} 72px ${MOBILE_SIDE_PADDING}`,
    alignItems: 'flex-start',
  },
}));

const Title = styled.div((props) => ({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 45,
  lineHeight: '52px',
  fontFamily: 'Taviraj',
  marginBottom: 32,
  color: props.theme.colors.backgroundDark,
  maxWidth: 600,
  letterSpacing: 0.2,
  [BREAKPOINTS[800]]: {
    textAlign: 'left',
  },
}));

const Subtitle = styled.div((props) => ({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  color: props.theme.colors.backgroundDark,
  maxWidth: 600,
  [BREAKPOINTS[800]]: {
    textAlign: 'left',
  },
}));

const Dash = styled.div((props) => ({
  height: 1,
  width: 54,
  borderBottom: `1px solid ${props.theme.colors.backgroundDark}`,
  marginTop: 42,
  [BREAKPOINTS[800]]: {
    display: 'none',
  },
}));

const Description: React.FC = () => (
  <Container>
    <Title>Earn commission by promoting wellness</Title>
    <Subtitle>
      Yours app has one of the largest content libraries out there for mind & body. Top-notch
      quality mindfulness content curated by mindfulness experts, with features suitable for every
      user. Exclusive selection for mindfulness, meditation, sleep, music and yoga.
    </Subtitle>
    <Dash />
  </Container>
);

export default Description;
