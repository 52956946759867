import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { BREAKPOINTS } from 'utils/constants';

export default styled(Link)((props) => ({
  color: props.theme.colors.lightSand,
  opacity: 0.7,
  textDecoration: 'none',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '21px',
  letterSpacing: 1,
  marginLeft: 37,
  ':hover': {
    opacity: 1,
  },
  [BREAKPOINTS[800]]: {
    margin: '8px 12px',
  },
}));
