import React from 'react';
import styled from '@emotion/styled';

import InternalLink from '../InternalFooterLink';

import { BREAKPOINTS, INTERNAL_LINKS } from 'utils/constants';

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingTop: 15,
  [BREAKPOINTS[800]]: {
    justifyContent: 'center',
  },
});

const StyledInternalLink = styled(InternalLink)({
  padding: '0 18px',
  margin: 0,
});

const PrivacyLink = styled(StyledInternalLink)({
  borderLeft: `1px solid rgba(255, 255, 255, 0.12)`,
});

const LegalText = styled.div((props) => ({
  color: props.theme.colors.white,
  opacity: 0.6,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
}));

const LegalLinks: React.FC = () => (
  <Container>
    <StyledInternalLink to={INTERNAL_LINKS.TERMS_AND_CONDITIONS}>Terms</StyledInternalLink>
    <PrivacyLink to={INTERNAL_LINKS.PRIVACY_POLICY}>Privacy</PrivacyLink>
    <LegalText>© {new Date().getFullYear()} Yours. All rights reserved.</LegalText>
  </Container>
);

export default LegalLinks;
