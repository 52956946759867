import React from 'react';
import styled from '@emotion/styled';

import { EXTERNAL_LINKS } from 'utils/constants';

interface IButton {
  text?: string;
  href?: EXTERNAL_LINKS;
}

const StyledButton = styled.a((props) => ({
  padding: '12px 0',
  maxWidth: 136,
  borderRadius: 8,
  backgroundColor: props.theme.colors.lightSand,
  color: props.theme.colors.darkText,
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 600,
  textDecoration: 'none',
  cursor: 'pointer',
  textAlign: 'center',
  ':hover': {
    backgroundColor: props.theme.colors.sand,
  },
}));

const Button: React.FC<IButton> = ({
  text = 'Try for Free',
  href = EXTERNAL_LINKS.FUNNEL,
  ...rest
}) => (
  <StyledButton href={href} {...rest}>
    {text}
  </StyledButton>
);

export default Button;
