import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import AffiliatePageHeader from 'components/AffiliatePageHeader';
import SEO from 'components/SEO';
import Hero from 'components/AffiliateComponents/Hero';
import Description from 'components/AffiliateComponents/Description';
import Instructions from 'components/AffiliateComponents/Instructions';
import ReasonsToChoose from 'components/AffiliateComponents/ReasonsToChoose';
import Footer from 'components/LightFooter';

export const query = graphql`
  query AffiliatePageQuery {
    allPrismicAffiliatePage {
      nodes {
        dataRaw
      }
    }
  }
`;

const Affiliate = ({ data }) => {
  const pageData = data.allPrismicAffiliatePage.nodes[0].dataRaw;

  return (
    <>
      <AffiliatePageHeader />
      <SEO
        pageTitle={pageData.page_title && RichText.asText(pageData.page_title)}
        ogTitle={pageData.og_title && RichText.asText(pageData.og_title)}
        twitterTitle={pageData.twitter_title && RichText.asText(pageData.twitter_title)}
        pageDescription={pageData.page_description && RichText.asText(pageData.page_description)}
        ogDescription={pageData.og_description && RichText.asText(pageData.og_description)}
        twitterDescription={
          pageData.twitter_description && RichText.asText(pageData.twitter_description)
        }
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <Hero />
      <Description />
      <ReasonsToChoose />
      <Instructions />
      <Footer />
    </>
  );
};

export default Affiliate;
