import React from 'react';
import styled from '@emotion/styled';

import Button from 'components/Button';
import { BREAKPOINTS, EXTERNAL_LINKS } from 'utils/constants';

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightSand,
  backgroundDark: props.theme.colors.backgroundDark,
  padding: `102px 104px 66px 104px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [BREAKPOINTS[800]]: {
    padding: `74px 4px`,
  },
}));

const Title = styled.div({
  fontFamily: 'Taviraj',
  fontWeight: 700,
  fontSize: 45,
  lineHeight: '52px',
  letterSpacing: 0.2,
  textAlign: 'center',
  margin: `0 16px 12px 16px`,
});

const Subtitle = styled.div({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'center',
  margin: `0 16px 12px 16px`,
});

const StepsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 64,
  [BREAKPOINTS[955]]: {
    flexDirection: 'column',
  },
  [BREAKPOINTS[440]]: {
    alignItems: 'stretch',
  },
});

const StepPaddingWrapper = styled.div({
  display: 'flex',
  flexBasis: 'min(33%, 360px)',
  margin: '16px',
  [BREAKPOINTS[440]]: {
    margin: '16px 0',
  },
});

const Step = styled.div((props) => ({
  backgroundColor: props.theme.colors.darkSand,
  display: 'grid',
  gridTemplateRows: 'min-content min-content 1fr',
  padding: '60px 32px 20px',
  borderRadius: 10,
  minHeight: 300,
  [BREAKPOINTS[955]]: {
    width: 296,
  },
  [BREAKPOINTS[440]]: {
    width: '100%',
    minHeight: 274,
    margin: '0 16px',
  },
}));

const StepNumber = styled.div((props) => ({
  fontFamily: 'Taviraj',
  color: props.theme.colors.black,
  opacity: 0.19,
  fontSize: 80,
}));

const StepTitle = styled.div((props) => ({
  fontFamily: 'Taviraj',
  color: props.theme.colors.black,
  fontWeight: 700,
  fontSize: 28,
  lineHeight: '42px',
  letterSpacing: 0.2,
  marginBottom: 18,
  marginTop: 12,
}));

const StepDescription = styled.div((props) => ({
  color: props.theme.colors.black,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.3,
}));

const StyledButton = styled(Button)((props) => ({
  backgroundColor: props.theme.colors.buttonGray,
  paddingLeft: 32,
  paddingRight: 32,
  color: props.theme.colors.white,
  alignSelf: 'center',
  maxWidth: 'unset',
  marginTop: 44,
  ':hover': {
    backgroundColor: 'rgba(45, 55, 57, 0.9)',
  },
  [BREAKPOINTS[440]]: {
    alignSelf: 'stretch',
    margin: '44px 16px 0 16px',
  },
}));

const Instructions: React.FC = () => (
  <Container>
    <Title>Get started today</Title>
    <Subtitle>Working with us is simple</Subtitle>
    <StepsContainer>
      <StepPaddingWrapper>
        <Step>
          <StepNumber>1.</StepNumber>
          <StepTitle>Apply online</StepTitle>
          <StepDescription>Fill out the application form - it’s easy and short!</StepDescription>
        </Step>
      </StepPaddingWrapper>
      <StepPaddingWrapper>
        <Step>
          <StepNumber>2.</StepNumber>
          <StepTitle>Get links & promo</StepTitle>
          <StepDescription>We will send you a personal link and a media package.</StepDescription>
        </Step>
      </StepPaddingWrapper>
      <StepPaddingWrapper>
        <Step>
          <StepNumber>3.</StepNumber>
          <StepTitle>Get paid</StepTitle>
          <StepDescription>Earn commission on your sales.</StepDescription>
        </Step>
      </StepPaddingWrapper>
    </StepsContainer>
    <StyledButton text="Become an Affiliate" href={EXTERNAL_LINKS.AFF_YOURS_APP} />
  </Container>
);

export default Instructions;
