import React from 'react';
import styled from '@emotion/styled';

import InternalLink from 'components/InternalFooterLink';
import ExternalLink from 'components/ExternalFooterLink';
import Logo from 'assets/svgs/logo.svg';
import { BREAKPOINTS, EXTERNAL_LINKS, INTERNAL_LINKS } from 'utils/constants';

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: 50,
  borderBottom: '1px solid rgba(255, 255, 255, 0.13)',
  [BREAKPOINTS[800]]: {
    justifyContent: 'center',
    paddingBottom: 24,
  },
});

const LinksContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const StyledLogo = styled(Logo)({
  height: 47,
  [BREAKPOINTS[800]]: {
    display: 'none',
  },
});

const InternalLinksContainer: React.FC = () => (
  <Container>
    <StyledLogo />
    <LinksContainer>
      <ExternalLink href={EXTERNAL_LINKS.STUDENT_BEANS}>For students</ExternalLink>
      <InternalLink to={INTERNAL_LINKS.BLOG}>Blog</InternalLink>
      <InternalLink to={INTERNAL_LINKS.CONTRIBUTORS}>Contributors</InternalLink>
      <ExternalLink href={EXTERNAL_LINKS.BUSINESS_WEB_APP}>Business</ExternalLink>
      <InternalLink to={INTERNAL_LINKS.ABOUT_US}>About us</InternalLink>
      <InternalLink to={INTERNAL_LINKS.AFFILIATE}>Affiliate program</InternalLink>
    </LinksContainer>
  </Container>
);

export default InternalLinksContainer;
