import React from 'react';
import styled from '@emotion/styled';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';
import Checkmark from 'assets/svgs/checkmark.svg';

const Container = styled.div((props) => ({
  color: props.theme.colors.white,
  background: 'radial-gradient(115.81% 311.92% at 87.39% 154.55%, #103E4B 0%, #000000 100%)',
  padding: `156px ${WEB_SIDE_PADDING} 136px ${WEB_SIDE_PADDING}`,
  display: 'flex',
  flexDirection: 'column',
  [BREAKPOINTS[800]]: {
    padding: `156px ${MOBILE_SIDE_PADDING} 136px ${MOBILE_SIDE_PADDING}`,
  },
}));

const Title = styled.div({
  fontFamily: 'Taviraj',
  fontWeight: 700,
  fontSize: 45,
  letterSpacing: 0.2,
  textAlign: 'center',
});

const StepsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 22,
  flexWrap: 'wrap',
});

const Step = styled.div({
  display: 'flex',
  padding: '0 10px',
  margin: '60px auto',
});

const StepTextContainer = styled.div({
  maxWidth: 300,
});

const StyledCheckmark = styled(Checkmark)({
  width: 10,
  height: 8,
});

const CheckmarkContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 25,
  minWidth: 25,
  margin: '10px 12px 0 0',
  borderRadius: 13,
  border: `1px solid ${props.theme.colors.white}`,
}));

const StepTitle = styled.div({
  fontWeight: 500,
  fontSize: 24,
  lineHeight: '42px',
  letterSpacing: 0.2,
  marginBottom: 7,
});

const StepDescription = styled.div({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '24px',
  letterSpacing: 0.3,
});

const ReasonsToChoose: React.FC = () => (
  <Container>
    <Title>Why choose to work with Yours app?</Title>
    <StepsContainer>
      <Step>
        <CheckmarkContainer>
          <StyledCheckmark />
        </CheckmarkContainer>
        <StepTextContainer>
          <StepTitle>PROMOTION IS EASY</StepTitle>
          <StepDescription>Promote Yours app anywhere via our referral link.</StepDescription>
        </StepTextContainer>
      </Step>
      <Step>
        <CheckmarkContainer>
          <StyledCheckmark />
        </CheckmarkContainer>
        <StepTextContainer>
          <StepTitle>PROFESSIONAL SUPPORT</StepTitle>
          <StepDescription>
            Get a dedicated Affiliate Manager who will help you maximize your conversions.
          </StepDescription>
        </StepTextContainer>
      </Step>
      <Step>
        <CheckmarkContainer>
          <StyledCheckmark />
        </CheckmarkContainer>
        <StepTextContainer>
          <StepTitle>CREATIVE LIBRARY</StepTitle>
          <StepDescription>
            We will provide you with all the media that you need to get started.
          </StepDescription>
        </StepTextContainer>
      </Step>
    </StepsContainer>
  </Container>
);

export default ReasonsToChoose;
