import React from 'react';

import NavButton from 'components/LightHeader/NavButton';
import { EXTERNAL_LINKS } from 'utils/constants';

interface INavigationLinks {
  isScrollMenu: boolean;
}

const NavigationLinks: React.FC<INavigationLinks> = ({ ...rest }) => (
  <NavButton href={EXTERNAL_LINKS.AFF_YOURS_APP} {...rest}>
    Apply Now
  </NavButton>
);

export default NavigationLinks;
